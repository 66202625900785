/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import handleViewport from 'react-in-viewport'
import Plaatjie from '@ubo/plaatjie'

// Images
import Netherlands from 'img/netherlands.inline.svg'
import Pin from 'img/pin.inline.svg'
import PinActive from 'img/pin-active.inline.svg'
import Location from 'img/location.inline.svg'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'

interface ProjectMapProps {
  // eslint-disable-next-line
  inViewport?: boolean
  forwardedRef?: any
  // eslint-disable-next-line react/no-unused-prop-types
  location?: any
}

const Pointer = styled(motion.div) <{ x: number; y: number; active: boolean }>`
  position: absolute;
  ${(props) =>
    props.x &&
    props.y &&
    css`
      top: ${`${props.y}%`};
      left: ${`${props.x}%`};
    `}

  & .active {
    position: relative;
    will-change: transform;
    top: -25px;
    left: -15px;
    z-index: 3;

    & path {
      fill: ${({ theme }) => theme.color.alternate};
    }
  }

  ${(props) =>
    props.active &&
    css`
      pointer-events: none;
    `}

  & .inactive {
    will-change: transform;
    position: relative;
    z-index: 2;
    & path {
      transition: all 0.3s ease-in-out;
    }
    & path {
      transition: all 0.3s ease-in-out;
      fill: ${({ theme }) => theme.color.primary};
    }
    &:hover path {
      fill: ${({ theme }) => theme.color.alternate};
    }
  }
`

const Post = styled.div`
  color: ${({ theme }) => theme.color.light};
  font-family: ${({ theme }) => theme.font.family.secondary};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 24px;
  @media (min-width: 992px) {
    height: 585px;
  }
  @media (max-width: 991px) {
    margin-bottom: -8rem;
  }
  @media (max-width: 575px) {
    margin-bottom: -4rem;
  }
`

const Inner = styled.div`
  z-index: 3;
  background: linear-gradient(
    302.67deg,
    rgba(99, 186, 233, 0.65) 12.47%,
    rgba(69, 161, 43, 0.65) 80.97%
  );

  & ul li {
    font-weight: ${({ theme }) => theme.font.weight.light};
    @media (min-width: 576px) {
      font-size: 18px;
    }
    @media (max-width: 575px) {
      font-size: 14px;
    }
  }
`

const Image = styled(Plaatjie)`
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
`

const LocationWrapper = styled.div`
  & svg {
    @media (min-width: 992px) {
      width: 33px;
      height: 33px;
    }
    @media (max-width: 991px) {
      width: 15px;
      height: 15px;
    }
  }
  & span {
    font-size: 20px;
  }
`

const ProjectMap: React.FC<ProjectMapProps> = ({
  inViewport,
  forwardedRef,
}) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.projectMapQueryQuery>(graphql`
    query projectMapQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
    }
  `)

  const posts: any = allWpProject.edges
  const filteredPosts: Array<any> = []

  posts
    .filter(
      (post: any) => post.node.recap.location.x && post.node.recap.location.y
    )
    .forEach((post: any) => {
      filteredPosts.push(post)
    })

  const [currentIndex, setCurrentIndex] = useState(0)

  const realPosts = filteredPosts.filter(
    (post: any) => post.node.flexContent.flex !== null
  )

  const { node } = realPosts[currentIndex]


  return (
    <section className="py-2 py-lg-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-8 col-lg-5 order-lg-2">
            <Post className="position-relative mx-lg-4">
              <Image
                image={node.recap.image}
                className="h-100 position-absolute"
                alt=""
              />
              <Inner className="position-relative h-100 d-flex flex-column justify-content-end p-3 p-sm-4">
                <LocationWrapper className="d-flex align-items-center mb-3">
                  <Location />
                  <span className="ms-2 ms-sm-3">
                    {node.recap.location.name}
                  </span>
                </LocationWrapper>
                <h2 className="mb-3">{node.title}</h2>
                {node.projectcategories && (
                  <ul>
                    {node.projectcategories.nodes.map((category: any) => (
                      <li>{category.name}</li>
                    ))}
                  </ul>
                )}
                <div className="d-none d-sm-flex">
                  <ButtonSecondary to={node.uri}>
                    Meer over dit project
                  </ButtonSecondary>
                </div>
                <div className="d-flex justify-content-end d-sm-none">
                  <AnchorPrimary to={node.uri}>Lees meer</AnchorPrimary>
                </div>
              </Inner>
            </Post>
          </div>
          <div className="col-lg-6 order-lg-1">
            <div className="position-relative">
              <Netherlands />
              {filteredPosts.map((filteredPost, index) => (
                <Pointer
                  key={index}
                  x={filteredPost.node.recap.location.x}
                  y={filteredPost.node.recap.location.y}
                  onClick={() => setCurrentIndex(index)}
                  initial={{ opacity: 0 }}
                  animate={inViewport && { y: [-100, 0], opacity: 1 }}
                  transition={{ delay: index * 0.3 }}
                  role="button"
                  ref={forwardedRef}
                  active={currentIndex === index}
                >
                  {currentIndex === index ? (
                    <PinActive className="active" />
                  ) : (
                    <motion.div
                      animate={{ y: [5, 0] }}
                      transition={{ yoyo: Infinity }}
                    >
                      <Pin className="inactive" />
                    </motion.div>
                  )}
                </Pointer>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const ViewportProjectMap = handleViewport(ProjectMap)

export default ViewportProjectMap
